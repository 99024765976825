import React, { useEffect, Component } from "react";
import img1 from "../Media/img-contact.jpg";
import img2 from "../Media/janni.png";
import img3 from "../Media/assistent.jpg";
import ContactForm from '../Components/ContactForm.tsx';

class Contact extends Component {
  

  render() {
    window.scrollTo(0, 0);
    return (
      <div className="contact">
        <div className="banner-container">
          <img className="img-banner" src={img1}></img>
          <div className="banner-title">Kontakt</div>
        </div>
        <div className="page-content-contact">


          <div className="profile-picture-text">
            <img src={img2} className="profile-picture" />
            <div>
              {" "}
              <b>Geschäftsführer</b>
            </div>
            <div>Janni Batsilas </div>
          </div>

          <div className="profile-picture-text">
            <img src={img3} className="profile-picture" />
            <div>
              {" "}
              <b>Leiterin Administration</b>
            </div>
            <div>Claudia Messano</div>
          </div>

          <div className="profile-picture-text">
          
          
          </div>
          <ContactForm />
        </div>
     
      </div>
    );
  }
}

export default Contact;
