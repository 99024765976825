import React, { useEffect } from 'react';

const AnotherCustomFormComponent = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the form-loader.js script
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007');
    // Load the ws-tracking.js script
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007');
  }, []);

  return (
    <>
      <div data-form-block-id="7038e29c-c2d3-ee11-9079-000d3adf733d"></div>
      <div id="dzFsrtGQu9SNRbV_FqDatEBORVhi3wCa0eA_jgIJf0Rw"></div>
      <div className="d365-mkt-config" style={{ display: 'none' }} data-website-id="zFsrtGQu9SNRbV_FqDatEBORVhi3wCa0eA_jgIJf0Rw" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </>
  );
};

export default AnotherCustomFormComponent;