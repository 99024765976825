import React, { useState } from "react";
import img1 from "../Media/img-home.jpg";
import img2 from "../Media/img-projects-1.png";
import img3 from "../Media/img-projects-2.jpg";
import img4 from "../Media/img-projects-3.png";
import img5 from "../Media/img-projects-4.JPG";
import img6 from "../Media/img-projects-5.jpeg";
import img7 from "../Media/img-projects-6.png";
import img8 from "../Media/img-projects-7.png";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

window.scrollTo(0, 0);
export default function Projects() {
  const [expandedState, setExpandedState] = useState({});

  const toggleExpand = (id, event) => {
    event.stopPropagation(); // Verhindert die Weitergabe des Klick-Events
    setExpandedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getClassNames = (id) => {
    return `img-text-r ${expandedState[id] ? "expanded" : ""}`;
  };

  const getText = (id) => {
    return expandedState[id] ? "Zusammenklappen" : "Mehr lesen ⇣";
  };

  return (
    <div className="cont">
      <div className="banner-container">
        <img className="img-banner" src={img1}></img>
        <div className="banner-title">Schwerpunkte</div>
      </div>

      <div className="page-content">


        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img8}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Microsoft Dynamics: Entwicklung oder Anpassung von Microsoft
                Dynamics Unternehmenslösungen
              </b>
            </p>
            <p className={getClassNames("block7")}>
              Wir optimieren Ihre Geschäfts&shy;prozesse mit
              maß&shy;geschneiderten Lösungen in Microsoft Dynamics 365. Diese
              Platt&shy;form ermöglicht eine tiefe Integration und
              Auto&shy;matisierung Ihrer Kern&shy;geschäfts&shy;abläufe. Von
              Kunden&shy;beziehungs&shy;management (CRM) bis hin zu Enterprise
              Resource Planning (ERP) – Microsoft Dynamics 365 stellt eine
              naht&shy;lose Verbindung zwischen allen Bereichen Ihres
              Unter&shy;nehmens her. Mit seiner adaptiven Archi&shy;tektur passt
              sich Dynamics 365 dynamisch an die evolutionären
              Anforder&shy;ungen Ihres Geschäfts an. Dabei gewähr&shy;leisten
              erst&shy;klassige Sicherheits&shy;- und
              Compliance&shy;-Maß&shy;nahmen, dass Ihre
              Unter&shy;nehmens&shy;daten stets sicher und geschützt sind.
              Entdecken Sie, wie Microsoft Dynamics 365 Ihre Effi&shy;zienz
              steigert und Ihnen hilft, smartere Geschäfts&shy;entscheidungen zu
              treffen.
            </p>
            <button
              onClick={(event) => toggleExpand("block7", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block3"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block7")}
            </button>
          </div>
        </div>

        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img7}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Microsoft Power Platform: Entwicklung individueller Anwendungen
                mit der Microsoft Power Platform und PowerApps
              </b>
            </p>
            <p className={getClassNames("block3")}>
              Wir entwickeln benutzer&shy;definierte Systeme in Microsoft Power
              Apps, die nahtlos in Ihre Arbeits&shy;abläufe integriert sind. Von
              einfachen Anwen&shy;dungen bis hin zu komplexen
              Unternehmens&shy;lösungen - Power Apps bietet die
              Flexibi&shy;lität, um sich an die sich ändernden
              Anforder&shy;ungen Ihres Unternehmens anzupassen. Microsoft Power
              Apps bietet erstk&shy;lassige Sicherheits- und
              Compliance-Funktionen, damit Ihre Apps und Daten immer geschützt
              sind.
            </p>
            <button
              onClick={(event) => toggleExpand("block3", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block3"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block3")}
            </button>
          </div>
        </div>      
          <div className="image-text-box-r">
          <ProgressiveImageCustom src={img3}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Entwicklung von Google Chrome Extensions: Maßgeschneiderte
                Browser-Erweiterungen für optimierte Nutzung und Produktivität
              </b>
            </p>
            <p className={getClassNames("block5")}>
              Möchten Sie Ihre Ideen und Funktionalitäten direkt in den
              Chrome-Browser integrieren? Mit unserer Expertise in der
              Entwicklung von Google Chrome Erweiterungen wird dies Realität.
              Wir entwickeln Chrome Extensions, welche die neusten
              Sicherheitsanforderungen (Manifest V.3) des Google Chrome Browsers
              erfüllen, vom UX-Design bis zum fertigen, ausführbarem Plugin.{" "}
            </p>
            <button
              onClick={(event) => toggleExpand("block5", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block5"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block5")}
            </button>
          </div>
        </div>
        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img5}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Datenstruktur&shy;analyse von Daten&shy;banken, Laboratory
                Inventory Management Systems (LIMS) und Electronic Laboratory
                Notebooks (ELN)
              </b>
            </p>
            <p className={getClassNames("block1")}>
              Sie möchten mehr aus Ihren Daten heraus holen? Wir
              identi&shy;fizieren Ineffi&shy;zienzen, Redun&shy;danzen und
              potenzielle Verbesse&shy;rungs&shy;bereiche und stellen sicher,
              dass Ihre Daten&shy;infrastruktur robust und auf Ihre
              Geschäfts&shy;ziele abge&shy;stimmt ist. Zu den
              Haupt&shy;bestand&shy;teilen einer Analyse gehören:<br></br>
              <br></br>
              &#x2022; Eine detaillierte Bewertung von Daten&shy;schema,
              Beziehungen und Abhängigkeiten. <br></br>
              &#x2022; Die Identifizierung von Engpässen und
              Leistungs&shy;problemen.<br></br>
              &#x2022; Empfehlungen zur Daten&shy;normalisierung und
              -optimierung.<br></br>
              &#x2022; Strategien für eine effektive Daten&shy;integration und
              -migration.<br></br>
              <br></br>
              Egal, ob Sie mit großen Daten&shy;banken, komplexen Data
              Warehouses oder neuen Daten&shy;technologien zu tun haben, unser
              Daten&shy;struktur&shy;analyse&shy;service ermöglicht Ihrem
              Unternehmen, das volle Potenzial Ihrer Daten&shy;bestände
              auszuschöpfen.
            </p>

            <button
              onClick={(event) => toggleExpand("block1", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block1"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block1")}
            </button>
          </div>
        </div>

        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img6}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Komplettlösungen für Digitale Präsenz: Design, Entwicklung und
                Langzeitbetreuung von Websites und Webapplikationen
              </b>
            </p>
            <p className={getClassNames("block2")}>
              Wir ge&shy;stal&shy;ten, ent&shy;wickeln und be&shy;treuen Ihre
              Web&shy;site oder Web&shy;App, in&shy;dem wir mo&shy;der&shy;ne
              Tech&shy;no&shy;lo&shy;gien wie das React-Fram&shy;e&shy;work
              so&shy;wie klas&shy;si&shy;sche Web&shy;ent&shy;wick&shy;lung mit
              HTML, CSS und JavaScript nutzen. Un&shy;ser An&shy;ge&shy;bot
              be&shy;schränkt sich nicht nur auf das De&shy;sign des
              Front&shy;ends. Wir küm&shy;mern uns eben&shy;falls um
              er&shy;weiterte Funk&shy;tionen, dar&shy;un&shy;ter
              Be&shy;nut&shy;zer&shy;an&shy;mel&shy;dungen oder die
              Ein&shy;bin&shy;dung von Web&shy;shops, kom&shy;plett mit der
              not&shy;wen&shy;digen Lo&shy;gik im Back&shy;end. Un&shy;ser
              An&shy;satz zielt dar&shy;auf ab, Ihre di&shy;gi&shy;ta&shy;le
              Prä&shy;senz mit einer Mischung aus tech&shy;ni&shy;schem
              Know&shy;how und krea&shy;tivem De&shy;sign zu
              ver&shy;stär&shy;ken. Wir le&shy;gen Wert dar&shy;auf,
              Lö&shy;sungen zu bie&shy;ten, die so&shy;wohl op&shy;tisch
              an&shy;spre&shy;chend als auch funk&shy;ti&shy;o&shy;nal sind, um
              die Be&shy;dürf&shy; nis&shy;se Ihrer Nut&shy;zer
              op&shy;ti&shy;mal zu er&shy;fül&shy;len.
            </p>
            <button
              onClick={(event) => toggleExpand("block2", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block2"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block2")}
            </button>
          </div>
        </div>

        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img2}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Prozessautomationen mit Trello: Automatisierung und
                Personalisierung von Trello-Boards mit individuellen Workflows
                und fortschrittlichen Integrationen
              </b>
            </p>
            <p className={getClassNames("block4")}>
              Von native Trello rules, Power-Ups bis hin zu
              Automati&shy;sierungs&shy;scripts, welche komplexe Aufgaben auf
              Ihrem Board übernehmen: Wir gestalten Ihre eigenen
              mass&shy;ge&shy;schneiderten Workflows, die genau auf Ihre
              Bedürfnisse zuge&shy;schnitten sind. Ganz gleich, ob es sich um
              Aufgaben&shy;zuweisungen, Status&shy;aktuali&shy;sierungen oder
              Benach&shy;richtig&shy;ungen handelt.
            </p>
            <button
              onClick={(event) => toggleExpand("block4", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block4"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block4")}
            </button>
          </div>
        </div>

        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img4}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>
                Spreadsheet Automationen: Maßgeschneiderte Lösungen für
                Effizienzsteigerung in Tabellenkalkulationsprogrammen
              </b>
            </p>
            <p className={getClassNames("block6")}>
              Wir implementieren Ihre mass&shy;ge&shy;schneiderten und
              performanten Auto&shy;matisierungs&shy;scripts in den gängigsten
              Tabellen&shy;kalkulations&shy;programmen wie Google Spreadsheet
              und Microsoft Excel.{" "}
            </p>
            <button
              onClick={(event) => toggleExpand("block6", event)}
              className="expand-collapse-btn"
              aria-label={
                expandedState["block6"] ? "Zusammenklappen" : "Mehr lesen ⇣"
              }
            >
              {getText("block6")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
