import React, { useEffect } from 'react';

const DynamicsFormAndTracking = () => {
  useEffect(() => {
    // Function to dynamically inject a script into the global scope
    const injectScript = (content) => {
      const script = document.createElement('script');
      script.textContent = content;
      document.body.appendChild(script);
    };

    // Inject the d365mktConfigureTracking function
    injectScript(`
      function d365mktConfigureTracking() {
        return {Anonymize: true};
      }
    `);

    // Function to dynamically load external scripts
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load form-loader.js script
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007");
    
    // Load ws-tracking.js script
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007");
  }, []);

  return (
    <div>
      <div data-form-block-id="fa694053-b4d3-ee11-9079-000d3adf7453"></div>
      <div id="dcjcBB1lbJE0r8XA-1R8igBmEnWFN734jkrfCQXv6dWk"></div>
      <div className="d365-mkt-config" style={{display:'none'}} data-website-id="cjcBB1lbJE0r8XA-1R8igBmEnWFN734jkrfCQXv6dWk" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </div>
  );
};

export default DynamicsFormAndTracking;
