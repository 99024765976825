import React, { useEffect } from 'react';

const FormWithTracking = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      document.body.appendChild(script);
    };

    // Load the external scripts
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007");
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007");

    // Cleanup to remove scripts on component unmount
    return () => {
      document.querySelectorAll("script[src^='https://mktdplp102cdn']").forEach(el => el.remove());
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <div data-form-block-id="242b40a3-d6d8-ee11-904b-000d3ab9c2cf"></div>
      <div id="dinlkTq-GLMvvqprLyuf6Y2A02_iz0XSNRGcyxLjC5-A"></div>
      <div className="d365-mkt-config" style={{display: 'none'}}
           data-website-id="inlkTq-GLMvvqprLyuf6Y2A02_iz0XSNRGcyxLjC5-A"
           data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </div>
  );
};

export default FormWithTracking;