import React, { useEffect } from 'react';

const MyFormComponent = () => {
  // Function to dynamically load a script
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  useEffect(() => {
    // Load external scripts after the component mounts
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007')
      .then(() => console.log('Form loader script loaded successfully.'))
      .catch((err) => console.error('Error loading form loader script:', err));

    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007')
      .then(() => console.log('WS tracking script loaded successfully.'))
      .catch((err) => console.error('Error loading WS tracking script:', err));
  }, []);

  return (
    <>
      <div data-form-block-id="562ad05d-1bde-ee11-904d-000d3a655ba9"></div>
      <div id="dztcAPY2h4ExsEzqLgULqKnTjZf5NWJy8zWTDdq8WhCo"></div>
      <div
        className="d365-mkt-config"
        style={{ display: 'none' }}
        data-website-id="ztcAPY2h4ExsEzqLgULqKnTjZf5NWJy8zWTDdq8WhCo"
        data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"
      ></div>
    </>
  );
};

export default MyFormComponent;