import React from "react";
import { Outlet } from 'react-router-dom';


export default function TEST_BT() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Outlet />
        </div>
    );
}
