import React, { useEffect } from 'react';

const TestMembersDE = () => {
  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load the form loader script
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007")
      .then(() => {
        console.log("Form loader script loaded successfully.");
        // Additional initialization code for the form can be added here if necessary
      })
      .catch(err => console.error(err));

    // Load the tracking script
    loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007")
      .then(() => {
        console.log("Tracking script loaded successfully.");
        // Additional tracking initialization code can be added here if necessary
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div>
      <div data-form-block-id="bb7f14ee-79cb-ee11-9079-000d3a655ba9"></div>
      <div id="d5wukq60LfR9ZDd038FoZ8NZsJqtruQUs5jxlx7kbGAw"></div>
      <div className="d365-mkt-config" style={{ display: 'none' }} data-website-id="5wukq60LfR9ZDd038FoZ8NZsJqtruQUs5jxlx7kbGAw" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </div>
  );
};

export default TestMembersDE;
