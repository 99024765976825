import React from 'react';

class SpreadsheetIframe extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <h2 style={styles.title}>Dokumentenablage und Links</h2>
        <iframe
          src="https://docs.google.com/spreadsheets/d/1rw-pCeHJUXyb5zBOcBxlVJZvmNoyI2TDn8M7x0lstxs/edit?usp=sharing"
          style={styles.iframe}
          title="Dokumentenablage und Links"
        ></iframe>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
  },
  title: {
    marginBottom: '20px',
  },
  iframe: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '80%', // Adjust width as per your requirement
    height: '600px', // Adjust height as per your requirement
  },
};

export default SpreadsheetIframe;