import React, { useState, useEffect } from "react";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";
import img1 from "../Media/img-home.jpg";
import img2 from "../Media/img-wellcome.jpg";
import img3 from "../Media/pax-8-logo.png";
import img4 from "../Media/Pax8.svg";
import Lottie from 'react-lottie';
import LottieAnimation from '../Components/LottieAnimation.tsx';


window.scrollTo(0, 0);

export default function Menu() {

  return (
    <div className="home">
      <div className="banner-container">
        <img className="img-banner" src={img1}></img>
        <div className="banner-title ">Über Uns</div>
      </div>

      <div className="page-content">
      <p className="why-next-node">Willkommen bei NextNode</p>



        <div className="image-text-box-r">
        <LottieAnimation animationKey="animation3" height={140} width={180} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-no-hide">
            Unser Auftrag ist, Ihr IT-Projekt Wirklichkeit
              werden zu lassen. Ganz gleich, ob Sie bereits eine klare
              Vorstellung von der Lösung haben, oder wir gemeinsam eine
              maßgeschneiderte Lösung entwickeln - wir sind für Sie da.
              <br></br>
            </p>
          </div>
        </div>



        <p className="why-next-node">Warum NextNode?</p>

        <div className="image-text-box-r">
        <LottieAnimation animationKey="animation1" height={160} width={180} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              In der heutigen Welt ist eine zuver&shy;lässige und effiziente
              digitale Infra&shy;struktur der entscheidende Baustein zum Erfolg.
              Bei NextNode verstehen wir, dass jede Organi&shy;sation in ihren
              Bedürfnissen und Anforder&shy;ungen einzigartig ist. Unser Ziel
              ist es so effizient wie möglich zu erruieren, welche Lösung Sie
              brauchen.
            </p>
          </div>
        </div>

        <div className="image-text-box-r">
        <LottieAnimation animationKey="animation2" height={200} width={180} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              NextNode ist kein IT-Koloss. Wir kommen aus dem ❤️ von Basel und
              Projekt&shy;realisierung mit uns bedeutet kurze
              Antwort&shy;zeiten, Eingehen auf individu&shy;elle Bedürfnisse und
              Änder&shy;ungen nach dem Prinzip der agilen Arbeits&shy;weise,
              offiziell zertifiziert durch Scrum Inc.{" "}
            </p>
          </div>
        </div>

        <div className="image-text-box-r">
        <LottieAnimation animationKey="animation4" height={140} width={180} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              Unser Partner&shy;netzwerk trägt jahr&shy;zehnte&shy;lange
              Erfahrung in der IT-Branche zusammen und verhilft Ihrem Projekt
              effizient, elegant und schlank zum Erfolg.
            </p>
          </div>
        </div>


        <div className="image-text-box-r">
        <img className="pax-8-logo" src={img4}></img>
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
            Durch unsere Partnerschaft mit pax8, dem führenden Anbieter im Cloud-Lizenz&shy;management, bieten wir Ihnen eine maß&shy;geschneiderte Verwaltung Ihrer Lizenz&shy;landschaft an. Unser Ziel ist es, Ihnen nicht nur Zeit und Ressourcen zu sparen, sondern auch sicher&shy;zustellen, dass Sie immer die richtigen Lizenzen für Ihre Bedürfnisse haben.

            </p>
          </div>
        </div>

      </div>
  </div>
  );
}
