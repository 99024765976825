import React, { useEffect } from 'react';

const MyFormComponent = () => {
    useEffect(() => {
        // Function to dynamically load scripts
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
        };

        // Load the required scripts
        loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007");
        loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007");

        // Cleanup function to remove scripts from the body on component unmount
        return () => {
            document.querySelectorAll("script[src*='mktdplp102cdn.azureedge.net']").forEach(script => {
                document.body.removeChild(script);
            });
        };
    }, []);

    return (
        <div>
            <div data-form-block-id="429af04e-61d3-ee11-9079-000d3adf7453"></div>
            <div id="dDkSVIK3juH-4klwVBmXEPUYyZCGrSzHUUy7Pn5JAQUw"></div>
            <div className="d365-mkt-config" style={{ display: 'none' }} data-website-id="DkSVIK3juH-4klwVBmXEPUYyZCGrSzHUUy7Pn5JAQUw" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
        </div>
    );
};

export default MyFormComponent;