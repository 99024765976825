import React, { useEffect } from 'react';

const CustomFormComponent = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the form-loader.js script
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007');
    // Load the ws-tracking.js script
    loadScript('https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007');
  }, []);

  return (
    <>
      <div data-form-block-id="ff3c8e18-bcd3-ee11-9079-000d3adf7453"></div>
      <div id="d_FYZcKzaSbat3VizFR_MNJWK7rJCUIuTHC-6LwJFju0"></div>
      <div className="d365-mkt-config" style={{ display: 'none' }} data-website-id="_FYZcKzaSbat3VizFR_MNJWK7rJCUIuTHC-6LwJFju0" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </>
  );
};

export default CustomFormComponent;