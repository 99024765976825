




import React from 'react';
import { IoLogInOutline } from "react-icons/io5";

const TestMenu = () => {
    const logout = () => {
        console.log('Logged out');
        // Implement actual logout logic here
        window.location.href = '/login'; // Navigate to login after logout
    };

    // Function to check if the button's path matches the current window location
    const isPathSelected = (path) => {
        return window.location.href.includes(path);
    };

    // Helper function to determine button style based on the current path
    const getButtonStyle = (path) => ({
        borderWidth: "2px",
        padding: "4px",
        margin: "5px",
        borderRadius: "5px",
        backgroundColor: isPathSelected(path) ? "#007bff" : "white", // Highlight selected button based on URL
        color: isPathSelected(path) ? "white" : "black",
        cursor: "pointer", // Add cursor pointer for better user experience
    });

    return (
        <nav style={{ background: '#f0f0f0', padding: '10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <svg className="bt-logo" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 300 300" width="100" preserveAspectRatio="xMidYMid meet">
                <metadata>
                    Created by potrace 1.10, written by Peter Selinger 2001-2011
                </metadata>
                <g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)" fill="black" stroke="none">
                    <path d="M430 1105 l0 -655 985 0 985 0 0 315 0 315 120 0 120 0 0 340 0 340 -1105 0 -1105 0 0 -655z m1430 315 l0 -280 -685 0 -685 0 0 280 0 280 685 0 685 0 0 -280z m720 0 l0 -280 -330 0 -330 0 0 280 0 280 330 0 330 0 0 -280z m-242 -630 l2 -280 -925 0 -925 0 0 283 0 282 922 -2 923 -3 3 -280z"></path>
                    <path d="M594 1601 c-2 -2 -4 -17 -4 -33 0 -28 1 -28 60 -28 l60 0 0 -155 0 -155 30 0 30 0 0 155 0 155 60 0 60 0 0 33 0 32 -146 0 c-80 0 -148 -2 -150 -4z"></path>
                    <path d="M940 1420 l0 -190 36 0 36 0 -3 78 -4 77 88 3 87 3 0 -81 0 -80 30 0 30 0 0 188 0 189 -30 0 -30 0 0 -79 0 -78 -88 0 -88 0 1 77 0 77 -32 3 -33 3 0 -190z"></path>
                    <path d="M1330 1419 l0 -189 30 0 30 0 0 180 c0 99 0 183 0 187 0 4 -13 8 -30 9 l-30 2 0 -189z"></path>
                    <path d="M1523 1590 c-89 -54 -62 -162 48 -189 73 -18 94 -33 94 -66 0 -65 -111 -69 -134 -4 -8 25 -15 29 -45 29 -33 0 -34 -1 -27 -28 19 -64 57 -96 129 -108 31 -5 51 -1 85 15 48 23 67 53 67 107 0 48 -34 79 -106 99 -78 21 -88 28 -92 57 -4 34 19 50 65 46 28 -2 41 -9 48 -25 7 -17 18 -23 42 -23 37 0 40 8 18 50 -30 59 -128 79 -192 40z"></path>
                    <path d="M2050 1419 l0 -189 30 0 30 0 0 180 c0 99 0 183 0 187 0 4 -13 8 -30 9 l-30 2 0 -189z"></path>
                    <path d="M2255 1600 c-37 -15 -67 -57 -68 -94 -1 -50 40 -91 107 -106 73 -17 98 -35 94 -68 -7 -63 -110 -66 -137 -4 -12 27 -20 32 -48 32 -31 0 -33 -2 -27 -27 11 -46 41 -80 86 -98 116 -46 238 51 186 148 -20 38 -44 51 -145 77 -49 12 -64 41 -37 71 25 28 78 25 102 -6 14 -17 30 -25 51 -25 17 0 31 3 31 6 0 21 -35 73 -59 87 -30 18 -102 22 -136 7z"></path>
                    <path d="M610 791 l0 -191 90 0 c107 0 152 15 175 60 20 39 13 81 -20 112 -23 22 -23 22 -4 46 28 37 24 86 -10 124 -29 33 -31 33 -130 36 l-101 4 0 -191z m178 113 c26 -18 28 -41 5 -66 -12 -13 -30 -18 -70 -18 l-53 0 0 43 c0 24 3 47 7 50 12 13 89 7 111 -9z m20 -156 c7 -7 12 -24 12 -38 0 -40 -18 -50 -87 -50 l-63 0 0 50 0 50 63 0 c36 0 68 -5 75 -12z"></path>
                    <path d="M985 798 c-38 -100 -71 -185 -73 -190 -2 -4 12 -8 31 -8 33 0 36 3 53 50 l18 50 76 0 75 0 18 -50 c17 -47 20 -50 53 -50 20 0 34 4 32 8 -1 5 -34 90 -73 190 l-70 182 -35 0 -35 0 -70 -182z m131 30 c10 -29 21 -56 22 -60 2 -4 -20 -8 -48 -8 -28 0 -50 4 -48 8 2 4 12 31 22 60 11 29 23 52 26 52 3 0 15 -23 26 -52z"></path>
                    <path d="M1365 971 c-38 -16 -60 -51 -60 -97 0 -58 24 -81 111 -105 62 -17 94 -38 94 -61 0 -5 -12 -20 -26 -35 -20 -20 -31 -24 -57 -19 -38 8 -67 33 -67 58 0 14 -8 18 -35 18 -27 0 -35 -4 -35 -19 0 -27 42 -84 74 -100 62 -29 171 -8 196 38 5 11 10 40 10 64 0 38 -5 49 -29 70 -17 14 -54 30 -83 37 -77 18 -90 27 -86 58 6 53 102 64 115 13 3 -13 16 -20 39 -23 30 -4 34 -1 34 20 0 14 -14 38 -34 58 -30 30 -40 34 -87 33 -30 0 -63 -4 -74 -8z"></path>
                    <path d="M1640 790 l0 -190 130 0 130 0 0 35 0 35 -99 0 -99 0 2 43 2 42 87 3 87 3 0 29 0 29 -87 3 -87 3 -2 43 -2 42 99 0 99 0 0 35 0 35 -130 0 -130 0 0 -190z"></path>
                    <path d="M1980 790 l0 -190 123 0 123 0 -4 35 -4 35 -87 0 -86 0 3 155 3 155 -36 0 -35 0 0 -190z"></path>
                </g>
            </svg>

            <div id="myButtonContainer">

                <button onClick={() => window.location.href = '/TEST_BT/DOCSTORAGE'} style={getButtonStyle('TEST_BT/DOCSTORAGE')}>
                    Ablage
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/ImageHosting'} style={getButtonStyle('TEST_BT/ImageHosting')}>
                    Image Hosting
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/de/TEST_GENERAL_DE'} style={getButtonStyle('TEST_GENERAL_DE')}>
                    General DE
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/it/TEST_GENERAL_IT'} style={getButtonStyle('TEST_GENERAL_IT')}>
                    General IT
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/fr/TEST_GENERAL_FR'} style={getButtonStyle('TEST_GENERAL_FR')}>
                    General FR
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/es/TEST_GENERAL_ES'} style={getButtonStyle('TEST_GENERAL_ES')}>
                    General ES
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/en/TEST_GENERAL_EN'} style={getButtonStyle('TEST_GENERAL_EN')}>
                    General EN
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/de/TEST_MEMBERS_DE'} style={getButtonStyle('TEST_MEMBERS_DE')}>
                    Members DE
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/en/TEST_MEMBERS_EN'} style={getButtonStyle('TEST_MEMBERS_EN')}>
                    Members EN
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/de/TEST_MEDIA_DE'} style={getButtonStyle('TEST_MEDIA_DE')}>
                    Media DE
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/it/TEST_MEDIA_IT'} style={getButtonStyle('TEST_MEDIA_IT')}>
                    Media IT
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/fr/TEST_MEDIA_FR'} style={getButtonStyle('TEST_MEDIA_FR')}>
                    Media FR
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/es/TEST_MEDIA_ES'} style={getButtonStyle('TEST_MEDIA_ES')}>
                    Media ES
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/en/TEST_MEDIA_EN'} style={getButtonStyle('TEST_MEDIA_EN')}>
                    Media EN
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/de/SUBSCRIPTION_CENTER_ALL'} style={getButtonStyle('SUBSCRIPTION_CENTER_ALL_DE')}>
                    Sub Center DE
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/it/SUBSCRIPTION_CENTER_ALL'} style={getButtonStyle('SUBSCRIPTION_CENTER_ALL_IT')}>
                    Sub Center IT
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/fr/SUBSCRIPTION_CENTER_ALL'} style={getButtonStyle('SUBSCRIPTION_CENTER_ALL_FR')}>
                    Sub Center FR
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/es/SUBSCRIPTION_CENTER_ALL'} style={getButtonStyle('SUBSCRIPTION_CENTER_ALL_ES')}>
                    Sub Center ES
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/en/SUBSCRIPTION_CENTER_ALL'} style={getButtonStyle('SUBSCRIPTION_CENTER_ALL_EN')}>
                    Sub Center EN
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/de/TEST_FORM_BOT'} style={getButtonStyle('TEST_FORM_BOT')}>
                    FORM BOT
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/BORDER_ADDING_APP'} style={getButtonStyle('BORDER_ADDING_APP')}>
                    Border Adding App
                </button>
                <button onClick={() => window.location.href = '/TEST_BT/IMAGE_CUTTING_APP'} style={getButtonStyle('IMAGE_CUTTING_APP')}>
                    Image Cutting App
                </button>

                <button onClick={logout} style={{
                    borderWidth: "2px",
                    padding: "6px",
                    margin: "5px",
                    borderRadius: "19px",
                    backgroundColor: "red",
                    color: 'white',
                    cursor: "pointer",
                }}>
                    <IoLogInOutline size={20} color="white" />
                </button>
            </div>
        </nav>
    );
};

export default TestMenu;
