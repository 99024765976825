import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  // Function to dynamically load scripts
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // URLs of the Dynamics Marketing scripts
    const formLoaderScriptUrl = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007";
    const trackingScriptUrl = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007";

    // Load the form loader script
    loadScript(formLoaderScriptUrl)
      .then(() => console.log("Form loader script loaded successfully."))
      .catch(err => console.error("Error loading form loader script:", err));

    // Load the tracking script
    loadScript(trackingScriptUrl)
      .then(() => console.log("Tracking script loaded successfully."))
      .catch(err => console.error("Error loading tracking script:", err));
  }, []);

  return (
    <div>
      <div data-form-block-id="fb424dca-d1cd-ee11-9079-000d3a655ba9"></div>
      <div id="dtawUmytWkt-n6USBDUnpR4CLKENPCNPZYSj5A2isWCw"></div>
      <div className="d365-mkt-config" style={{ display: 'none' }} data-website-id="tawUmytWkt-n6USBDUnpR4CLKENPCNPZYSj5A2isWCw" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </div>
  );
};

export default DynamicsMarketingForm;
