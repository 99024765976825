import React, { useState } from 'react';
import { useAuth } from './AuthProvider.tsx'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file for styling
import img1 from "../Media/img-contact.jpg";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Generate a simple captcha challenge
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const correctAnswer = num1 + num2;

    // Ask the user to solve the captcha
    const userAnswer = window.prompt(`Bitte lösen Sie das Captcha: ${num1} + ${num2} = ?`);

    // Check if the captcha is solved correctly
    if (parseInt(userAnswer) !== correctAnswer) {
      alert('Falsche Captcha-Antwort. Bitte versuchen Sie es erneut.');
      return; // Stop the login process if captcha is wrong
    }

    // Proceed with login if captcha is correct
    if (username === 'basel' && password === '78947894*') {
      login(); // Call the login function from useAuth hook
      const redirectPath = localStorage.getItem('postLoginRedirectPath') || '/TEST_BT/DOCSTORAGE';
      navigate(redirectPath); // Navigate to the previously attempted path or the default path
      localStorage.removeItem('postLoginRedirectPath'); // Clean up the post-login redirect path from local storage
    } else {
      alert('Passwort oder Username falsch.'); // Alert the user to the incorrect credentials
    }
  };

  return (
    <div className="contact">
    <div className="banner-container">
    <img className="img-banner" src={img1}></img>
    <div className="banner-title">Login</div>
  </div>
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>User:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Passwort:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <button type="submit" className="login-button">Log In</button>
      </form>
    </div>
    </div>
  );
};

export default LoginPage;
