


import { CiSaveDown2 } from "react-icons/ci";
import React, { useState, useRef, useEffect } from 'react';
import './BORDER_ADDING_APP.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

// Your Firebase configuration
const firebaseConfig = {
  // Your Firebase config details
  storageBucket: "gs://nn-file-storage.appspot.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

function ImageEditor() {
    const [lineColor, setLineColor] = useState('#ff8767');
    const [lineThickness, setLineThickness] = useState(3);
    const [inset, setInset] = useState(14);
    const [imageSrc, setImageSrc] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const canvasRef = useRef(null);

  useEffect(() => {
    drawImageWithBorder();
  }, [lineColor, lineThickness, inset, imageFile]);
  // Function to draw the image with border
  const drawImageWithBorder = () => {
    if (!imageFile) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = canvasRef.current;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        ctx.strokeStyle = lineColor;
        ctx.lineWidth = lineThickness;

        // Use the `inset` state for drawing the rectangle
        const rectWidth = img.width - (inset * 2);
        const rectHeight = img.height - (inset * 2);
        ctx.strokeRect(inset, inset, rectWidth, rectHeight);

        const dataUrl = canvas.toDataURL();
        setImageSrc(dataUrl);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  // Effect to redraw the image when line color, thickness, or inset changes
  useEffect(() => {
    drawImageWithBorder();
  }, [lineColor, lineThickness, inset, imageFile]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImageFile(file); // Store the image file for redrawing
    }
  };

  const downloadImage = () => {
    if (!imageSrc) return;
    const a = document.createElement('a');
    a.href = imageSrc;
    a.download = 'custom-image.png'; // Set the name of the downloaded file
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  const uploadImageToFirebase = async () => {
    setUploadStatus('Uploading...'); // Inform user that upload is in progress
    if (!canvasRef.current) return;
    canvasRef.current.toBlob(blob => {
      const uploadPath = `images/bordered_${new Date().getTime()}.png`;
      const imageRef = storageRef(storage, uploadPath);
      uploadBytes(imageRef, blob).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setUploadStatus('Upload successful!'); // Inform user of success
          window.prompt('Copy to clipboard: Ctrl+C, Enter', url);
          // Optional: Display the image or URL to the user
        });
      }).catch(error => {
        setUploadStatus('Upload failed. Please try again.'); // Inform user of failure
        console.error('Upload failed:', error);
      });
    }, 'image/png');
  };

  return (
    <div className="image-editor">
      <div className="controls">
        <input type="file" onChange={handleImageChange} accept="image/*" />
        {['#ff8767', '#ffeb2b', '#4dd1b7', '#6290e7', '#7a5fc4'].map(color => (
          <button key={color} style={{ backgroundColor: color }} onClick={() => setLineColor(color)}></button>
        ))}
        <input type="range" min="1" max="10" value={lineThickness} onChange={(e) => setLineThickness(e.target.value)} title="Line Thickness" />
        <input type="range" min="0" max="50" value={inset} onChange={(e) => setInset(e.target.value)} title="Inset" />
        <button className="DownloadButton" onClick={downloadImage}><CiSaveDown2 /></button>
        <button onClick={uploadImageToFirebase}>Add to Image Hosting</button>
      </div>
      {imageSrc && <img src={imageSrc} alt="Preview" className="image-preview" />}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
}

export default ImageEditor;