import React, { useState, useRef, useEffect } from 'react';
import './IMAGE_CUTTING_APP.css';

function ImageEditor() {
  const [imageFile, setImageFile] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [crop, setCrop] = useState({ x: 50, y: 50, width: 200, height: 200 });
  const defaultCrop = { x: 50, y: 50, width: 160, height: 195 };
  const defaultMobile = { x: 50, y: 50, width: 280, height: 100 };
  const [dragging, setDragging] = useState(false);
  const [resizeCorner, setResizeCorner] = useState(null);
  const canvasRef = useRef(null);
  const [isLocked, setIsLocked] = useState(false);
  const [keepAspect, setKeepAspect] = useState(false);
  const imageRef = useRef(null);

  const resetCrop = () => {
    setCrop({ ...defaultCrop });
    updateCanvas();  // Ensure the canvas is updated immediately with the reset
  };

  const resetCropMobile = () => {
    setCrop({ ...defaultMobile });
    updateCanvas();  // Ensure the canvas is updated immediately with the reset
  };

  const cropImage = () => {
    if (!imageRef.current || !canvasRef.current) return;

    // Create a new canvas where the cropped image will be drawn
    const cropCanvas = document.createElement('canvas');
    const ctx = cropCanvas.getContext('2d');
    cropCanvas.width = crop.width;
    cropCanvas.height = crop.height;

    // Draw the cropped area of the original image into the new canvas
    ctx.drawImage(
      imageRef.current,
      crop.x, crop.y, crop.width, crop.height,
      0, 0, crop.width, crop.height
    );

    // Convert the canvas to a data URL or create a Blob to download
    cropCanvas.toBlob(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'cropped-image.png'; // Name the file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Clean up
    }, 'image/png');
  };

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          imageRef.current = img;
          updateCanvas();
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  const toggleLock = () => {

    // Lock the rectangle
    const imageHeight = imageRef.current.height;
    const imageWidth = imageRef.current.width;
    const newWidth = imageHeight / 1.2; // Calculate the width based on the aspect ratio

    setCrop({
      x: (imageWidth - newWidth) / 2, // Center horizontally
      y: 0, // Start at the top of the canvas
      width: newWidth,
      height: imageHeight // Span the entire height of the image
    });

    setIsLocked(!isLocked); // Toggle the lock state
    updateCanvas(); // Update the canvas to reflect changes
  };


  const toggleLockMobile = () => {

    // Lock the rectangle
    const imageHeight = imageRef.current.height;
    const imageWidth = imageRef.current.width;
    const newWidth = imageHeight / 0.35; // Calculate the width based on the aspect ratio

    setCrop({
      x: (imageWidth - newWidth) / 2, // Center horizontally
      y: 0, // Start at the top of the canvas
      width: newWidth,
      height: imageHeight // Span the entire height of the image
    });

    setIsLocked(!isLocked); // Toggle the lock state
    updateCanvas(); // Update the canvas to reflect changes
  };

  const updateCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas || !imageRef.current) return;

    const ctx = canvas.getContext('2d');
    canvas.width = imageRef.current.width;
    canvas.height = imageRef.current.height;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(imageRef.current, 0, 0);

    ctx.strokeStyle = '#FF0000';
    ctx.lineWidth = 2;
    ctx.strokeRect(crop.x, crop.y, crop.width, crop.height);

    ctx.fillStyle = '#00FF00';
    const cornerSize = 10;
    ctx.fillRect(crop.x - cornerSize / 2, crop.y - cornerSize / 2, cornerSize, cornerSize); // Top-left
    ctx.fillRect(crop.x + crop.width - cornerSize / 2, crop.y - cornerSize / 2, cornerSize, cornerSize); // Top-right
    ctx.fillRect(crop.x - cornerSize / 2, crop.y + crop.height - cornerSize / 2, cornerSize, cornerSize); // Bottom-left
    ctx.fillRect(crop.x + crop.width - cornerSize / 2, crop.y + crop.height - cornerSize / 2, cornerSize, cornerSize); // Bottom-right
  };

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;
    const cornerSize = 10; // Increased for easier interaction

    if (Math.abs(x - crop.x) <= cornerSize && Math.abs(y - crop.y) <= cornerSize) {
      setResizeCorner('tl');
    } else if (Math.abs(x - (crop.x + crop.width)) <= cornerSize && Math.abs(y - crop.y) <= cornerSize) {
      setResizeCorner('tr');
    } else if (Math.abs(x - crop.x) <= cornerSize && Math.abs(y - (crop.y + crop.height)) <= cornerSize) {
      setResizeCorner('bl');
    } else if (Math.abs(x - (crop.x + crop.width)) <= cornerSize && Math.abs(y - (crop.y + crop.height)) <= cornerSize) {
      setResizeCorner('br');
    } else {
      setResizeCorner(null);
      setDragging(true);
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging && !resizeCorner) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    if (dragging) {
      const maxX = imageRef.current.width - crop.width;
      const maxY = imageRef.current.height - crop.height;
      const newX = Math.max(0, Math.min(x - crop.width / 2, maxX));
      const newY = Math.max(0, Math.min(y - crop.height / 2, maxY));

      setCrop(prev => ({
        ...prev,
        x: newX,
        y: newY
      }));
    }

    if (resizeCorner && keepAspect) {
      const aspectRatio = crop.width / crop.height;
      let newWidth, newHeight;

      switch (resizeCorner) {
        case 'tl': // Top-left corner
        case 'br': // Bottom-right corner
          if (Math.abs((x - crop.x) / aspectRatio) > Math.abs(y - crop.y)) {
            newWidth = Math.abs(x - crop.x);
            newHeight = newWidth / aspectRatio;
          } else {
            newHeight = Math.abs(y - crop.y);
            newWidth = newHeight * aspectRatio;
          }
          break;
        case 'tr': // Top-right corner
        case 'bl': // Bottom-left corner
          if (Math.abs((x - crop.x) / aspectRatio) > Math.abs(y - crop.y)) {
            newWidth = Math.abs(x - crop.x);
            newHeight = newWidth / aspectRatio;
          } else {
            newHeight = Math.abs(y - crop.y);
            newWidth = newHeight * aspectRatio;
          }
          break;
      }

      // Constrain to prevent going out of bounds
      const maxX = imageRef.current.width;
      const maxY = imageRef.current.height;
      newWidth = Math.min(newWidth, maxX);
      newHeight = Math.min(newHeight, maxY);

      // Prevent cropping rectangle from moving outside the image
      const newX = Math.max(0, Math.min(resizeCorner === 'tr' || resizeCorner === 'br' ? crop.x : x, maxX - newWidth));
      const newY = Math.max(0, Math.min(resizeCorner === 'bl' || resizeCorner === 'br' ? crop.y : y, maxY - newHeight));

      setCrop({
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight
      });
    }
    else if (resizeCorner) {
      if (resizeCorner && !keepAspect) {
        // Handle resizing without keeping aspect ratio
        switch (resizeCorner) {
          case 'tl': // Top-left corner
            setCrop(prev => ({
              ...prev,
              x: x,
              y: y,
              width: prev.width + (prev.x - x),
              height: prev.height + (prev.y - y)
            }));
            break;
          case 'tr': // Top-right corner
            setCrop(prev => ({
              ...prev,
              y: y,
              width: x - prev.x,
              height: prev.height + (prev.y - y)
            }));
            break;
          case 'bl': // Bottom-left corner
            setCrop(prev => ({
              ...prev,
              x: x,
              width: prev.width + (prev.x - x),
              height: y - prev.y
            }));
            break;
          case 'br': // Bottom-right corner
            setCrop(prev => ({
              ...prev,
              width: x - prev.x,
              height: y - prev.y
            }));
            break;
        }
      }

    }

    updateCanvas();
  };

  const handleMouseUp = () => {
    setDragging(false);
    setResizeCorner(null);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImageFile(file);
    }
  };

  return (
    <div className="image-editor">
      <div className="controls">
        <input type="file" onChange={handleImageChange} accept="image/*" />
        <button onClick={cropImage}>CUT</button>
        <button onClick={toggleLock}>MAXIMALE HÖHE DESKTOP</button>
        <button onClick={toggleLockMobile}>MAXIMALE HÖHE MOBIL</button>
        <button onClick={resetCrop}>DESKTOP</button>
        <button onClick={resetCropMobile}>MOBIL</button>
        <label>
          <input
            type="checkbox"
            checked={keepAspect}
            onChange={() => setKeepAspect(!keepAspect)}
          /> Keep Aspect Ratio
        </label>
      </div>
      <canvas className="canvas" ref={canvasRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} />
    </div>
  );
}

export default ImageEditor;
