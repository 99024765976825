import { useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import React from "react";

export default function ParticleBanner() {

    const particlesInit = useCallback(async (engine: Engine) => {
        console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        await console.log(container);
    }, []);
    
    return (
        <Particles className="particles-container"
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{

                background: {
                    color: {
                        value: "FFFFFF",
                    },
                },
                fullScreen: {
                    enable: true,
                    zIndex: -10 // or any value is good for you, if you use -1 set `interactivity.detectsOn` to `"window"` if you need mouse interactions
                  },
                fpsLimit: 80,
                interactivity: {
                    detect_on: "window",
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                            
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 5,
                        },
                        repulse: {
                            distance: 0,
              duration: 2,
              speed: 1
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#cfcfcf",
                    },
                    links: {
                        color: "#cfcfcf",
                        distance: 200,
                        enable: true,
                        opacity: 0.65,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        limit: 45,
                        value: 35
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        />
    );
};