import React, { useEffect } from 'react';

function AutoFormFiller() {
    useEffect(() => {
        // Dynamically load the external scripts after the component mounts
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
        };

        loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007");
        loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007");

        return () => {
            // Cleanup the scripts when the component unmounts
            document.querySelectorAll('script[src^="https://mktdplp102cdn.azureedge.net"]').forEach(script => script.remove());
        };
    }, []);

    return (
        <div>
            <div data-form-block-id="9e7fb1f0-8c09-ef11-9f89-6045bde0e31d"></div>
            <div id="des2PHN43Xq3rueajyPTckWEescR5bdAMgLjSRIWT6SU"></div>
            <div className="d365-mkt-config" style={{display: 'none'}} data-website-id="es2PHN43Xq3rueajyPTckWEescR5bdAMgLjSRIWT6SU" data-hostname="c4c6299c719f4b0d882dec4c234b5e18.svc.dynamics.com"></div>
        </div>
    );
}

export default AutoFormFiller;
