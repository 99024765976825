import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css'; // Import the CSS file for styling

function ContactForm() {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Generate a simple captcha challenge
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const correctAnswer = num1 + num2;

    // Ask the user to solve the captcha
    const userAnswer = window.prompt(`Bitte lösen Sie das Captcha: ${num1} + ${num2} = ?`);

    // Check if the captcha is solved correctly
    if (parseInt(userAnswer) === correctAnswer) {
      setIsSubmitting(true);
      // Proceed with sending the email using emailjs
      emailjs.send('service_5vjhbv7', 'template_h7t4xcq', formData, 'Z2hQ_lZVSOpiHOHdw')
        .then((result) => {
          alert('Vielen Dank für Ihre E-Mail! Wir werden Ihnen so rasch wie möglich Antworten.');
          setFormData({ user_name: '', user_email: '', message: '' }); // Reset form fields
        }, (error) => {
          alert('Oh mann, da lief etwas schief :(');
        }).finally(() => setIsSubmitting(false)); // Reset submission state
    } else {
      alert('Falsche Captcha-Antwort. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <form className="email-form" onSubmit={handleSubmit}>
      <input
        className="input-form"
        type="text"
        name="user_name"
        placeholder="Name"
        value={formData.user_name}
        onChange={handleChange}
      />
      <input
        className="input-form"
        type="email"
        name="user_email"
        placeholder="Email"
        value={formData.user_email}
        onChange={handleChange}
      />
      <textarea
        className="input-form message"
        name="message"
        placeholder="Nachricht"
        value={formData.message}
        onChange={handleChange}
      />
      {!isSubmitting && <button type="submit">Senden</button>}
    </form>
  );
}

export default ContactForm;
