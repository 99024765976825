// Firebase v9+ import syntax for Firebase App
import { initializeApp } from 'firebase/app';

import React, { useState, useEffect } from 'react';
// Import other Firebase services in a similar modular fashion
import {getStorage,  ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage';

// Firebase project configuration
const firebaseConfig = {
  // Your Firebase config
  storageBucket: "gs://nn-file-storage.appspot.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);


function ImageUpload() {
  const [images, setImages] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);

  const imagesListRef = ref(storage, 'images/');

  const uploadImage = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImages((prevImages) => {
          // Check if the image is already in the state before adding
          const exists = prevImages.some(image => image.name === snapshot.ref.name);
          if (!exists) {
            return [...prevImages, { name: snapshot.ref.name, url: url }];
          }
          return prevImages;
        });
      });
    });
  };

  const deleteImage = (image) => {
    const imageRef = ref(storage, `images/${image.name}`);
    deleteObject(imageRef).then(() => {
      setImages(images.filter((img) => img.name !== image.name));
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      const fetchPromises = response.items.map((item) => {
        return getDownloadURL(item).then((url) => {
          return { name: item.name, url: url };
        });
      });
  
      Promise.all(fetchPromises).then((images) => {
        setImages(images); // Resets and sets the images state with freshly fetched data
      });
    });
  }, []); // De


  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      minHeight: '100vh', 
      fontFamily: 'Arial, sans-serif', 
      backgroundColor: '#f5f5f7'
    }}>
      <div style={{ 
        marginTop: '20px',
        minHeight: '50vh', 
        maxHeight: '50vh', 
        overflowY: 'auto', 
        width: '90%', 
        margin: '0 auto', 
        backgroundColor: '#fff', 
        borderRadius: '12px', 
        padding: '20px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        {images.map((image) => (
          <div key={image.name} style={{ 
            display: 'flex', 
            alignItems: 'center', 
            width: '100%',
            marginBottom: '10px'
          }}>
            <img src={image.url} alt={image.name} style={{ width: "50px", borderRadius: '8px', marginRight: '20px' }} />
            <p style={{ flexGrow: 1, marginRight: 'auto' }}>{image.name}</p>
            <button onClick={() => deleteImage(image)} style={{ 
              border: 'none', 
              borderRadius: '5px', 
              padding: '5px 10px', 
              cursor: 'pointer', 
              backgroundColor: '#007bff', 
              color: 'white', 
              transition: 'background-color 0.3s ease'
            }} onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}>Delete</button>
          </div>
        ))}
      </div>
 <div style={{ 
  display: 'flex', 
  flexWrap: 'wrap',
  justifyContent: 'center', 
  alignItems: 'center', 
  width: '90%', // Match the width of the list for alignment
  margin: '10px auto', // Center horizontally
}}>
  <input type="file" onChange={(e) => setImageUpload(e.target.files[0])} style={{ 
    padding: '10px',
    flexGrow: 1, // Allows the input to grow and fill the space
    marginRight: '10px', // Space between the input and the button
    cursor: 'pointer',
    borderRadius: '5px',
    border: '1px solid #d1d1d6', // Subtle border color
    backgroundColor: '#fff',
  }} />
  <button onClick={uploadImage} style={{ 
    border: 'none', 
    borderRadius: '5px', 
    padding: '10px 20px', 
    cursor: 'pointer', 
    margin: '10px',
    backgroundColor: '#28a745', 
    color: 'white', 
    transition: 'background-color 0.3s ease',
  }} onMouseOver={(e) => e.target.style.backgroundColor = '#218838'}
    onMouseOut={(e) => e.target.style.backgroundColor = '#28a745'}>Upload Image</button>
</div>
    </div>
  );
}

export default ImageUpload;