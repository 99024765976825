import React from "react";

import img1 from "../Media/img-service.jpg";
import img3 from "../Media/img-service-2.JPG";
import img4 from "../Media/img-service-3.JPG";

import { BiLogoJava } from "react-icons/bi";
import { IoLogoPython } from "react-icons/io";
import { TbSql } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { BiLogoReact } from "react-icons/bi";
import { BsMicrosoft } from "react-icons/bs";
import { FaChrome } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { AiFillHtml5 } from "react-icons/ai";
import { BiLogoCss3 } from "react-icons/bi";
import { TbBrandNpm } from "react-icons/tb";
import { SiMysql } from "react-icons/si";
import { SiTypescript } from "react-icons/si";

import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function Service() {
  window.scrollTo(0, 0);
  return (
    <div className="contact">
      <div className="banner-container">
        <img className="img-banner" src={img1}></img>
        
        <div className="banner-title">Dienstleistungen</div>
      </div>

      <div className="page-content">
        <div className="image-text-box-r">
        <ProgressiveImageCustom src={img3}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="tile-title">
              <b>Software Projektmanagement</b>
            </p>
            <p className="img-text-r-no-hide">
              {" "}
              Kein Projekt gleicht dem anderen, und wir passen uns an Ihre
              speziellen Anforderungen laufend an, da wir großen Wert auf eine
              agile Arbeitsweise und Kommunikation legen. Gemeinsam erarbeiten wir eine mass&shy;geschneiderte
              Softwarelösung, die perfekt zu Ihnen passt. Uns ist zudem wichtig
              nicht nur den aktuellen Bedarf abzudecken, sondern auch zukünftige
              Entwicklungen einzu&shy;kalkulieren, um Ihre Investition
              lang&shy;fristig zu schützen.
            </p>
            <div className="skillset">
              <BiLogoJava className="react-icons" size={45} color="#FFFFFF" />
              <IoLogoPython className="react-icons" size={45} color="#FFFFFF" />
              <TbSql className="react-icons" size={45} color="#FFFFFF" />
              <SiJavascript className="react-icons" size={45} color="#FFFFFF" />
              <BiLogoReact className="react-icons" size={45} color="#FFFFFF" />
              <BsMicrosoft className="react-icons" size={45} color="#FFFFFF" />
              <FaChrome className="react-icons" size={45} color="#FFFFFF" />
              <BiLogoPostgresql
                className="react-icons"
                size={45}
                color="#FFFFFF"
              />
              <AiFillHtml5 className="react-icons" size={45} color="#FFFFFF" />
              <BiLogoCss3 className="react-icons" size={45} color="#FFFFFF" />
              <TbBrandNpm className="react-icons" size={45} color="#FFFFFF" />
              <SiMysql className="react-icons" size={45} color="#FFFFFF" />
              <SiTypescript className="react-icons" size={45} color="#FFFFFF" />
            </div>
            <p className="img-text-r-no-hide">
              Wir bedienen eine breite Palette von Programm&shy;iersprachen und
              Techno&shy;logien und ziehen wenn nötig die richtigen Experten
              dazu. Egal ob Sie eine Webanwendung, eine mobile App oder
              Unternehmens&shy;software benötigen – wir haben das Know-how, um
              Ihre Vision in die Realität umzu&shy;setzen.
            </p>
          </div>
        </div>
        <div className="image-text-box-r">
        <ProgressiveImageCustom src={img4}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="tile-title">
              <b>Webdesign</b>
            </p>
            <p className="img-text-r-no-hide">
              {" "}
              Wir kreieren mass&shy;geschneiderte Websites, die nicht nur
              beein&shy;drucken, sondern auch nahtlose Benutzer&shy;erfahrungen
              bieten. Von modernem Responsive Design bis zur perfekten
              Ver&shy;schmelzung Ihrer Marken&shy;ästhetik.
            </p>
            <p className="img-text-r-no-hide">
              {" "}
              Wir sind nicht nur während der Entwicklungs&shy;phase an Ihrer
              Seite, sondern auch danach. Unsere Wartungs- und
              Supportdienst&shy;leistungen sorgen dafür, dass Ihre Website immer
              auf dem neuesten Stand ist und reibungslos läuft.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
