import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  // Function to dynamically load scripts
  const loadScript = src => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(script);
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // Load the form and tracking scripts
    const formScriptUrl = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007";
    const trackingScriptUrl = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007";

    loadScript(formScriptUrl).then(() => {
      console.log("Form script loaded successfully.");
    }).catch(err => {
      console.error("Failed to load form script:", err);
    });

    loadScript(trackingScriptUrl).then(() => {
      console.log("Tracking script loaded successfully.");
    }).catch(err => {
      console.error("Failed to load tracking script:", err);
    });
  }, []);

  return (

    <div>
      <div data-form-block-id="5c7a610d-7ecd-ee11-9079-000d3adf733d"></div>
      <div id="dcHNHoS3BoFgm2NhoZ6vNSFVMbKQfyj-uwrHYLwXFq_s"></div>
      <div className="d365-mkt-config" style={{display: 'none'}} data-website-id="cHNHoS3BoFgm2NhoZ6vNSFVMbKQfyj-uwrHYLwXFq_s" data-hostname="6fe14388935e42d5b8513078a478ecf5.svc.dynamics.com"></div>
    </div>

  );
};

export default DynamicsMarketingForm;