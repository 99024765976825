import ProgressiveImage from "react-progressive-graceful-image";
import React from "react";

export default function ProgressiveImageCustom({ src }) {
  return (
    // ...
    <ProgressiveImage  delay={1000} src={src} placeholder={src}>
      {(src, loading) => (
        <img
          className={`image-listing-1-r wiggle${loading ? " loading" : " loaded"}`}
          src={src}
          alt=""
        />
      )}
    </ProgressiveImage>
  );
}